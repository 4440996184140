import React from 'react';
import './styles/index.scss';

export default class Reset extends React.Component {
  render() {
    return (
      <div className="component-reset">
        <div className="reset-info">
          <h3 className="info-title">Uus parool</h3>
          <p className="info-text">Palun sisestage oma uus parool.</p>
        </div>
        <div className="reset-fields">
          <div className="field">
            <label htmlFor="password">Uus parool</label>
            <input type="password" name="phone" id="phone" />
          </div>
          <div className="field">
            <label htmlFor="password">Kinnita uus parool</label>
            <input type="password" name="phone" id="phone" />
          </div>
        </div>
        <button>Kinnita</button>
      </div>
    );
  }
}
